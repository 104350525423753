import React from 'react';
import {ReactComponent as LoadingLogo} from './loading.svg';
import './loading.css'

const Loading = props => 
<div className={"loading-backdrop" + (props.show ? " d-flex": " d-none")}>
  <LoadingLogo/>
  {props.text && <div className="d-flex">{[...props.text].map((c,i)=><p key={`loading_p${i}`}>{c}</p>)}</div>}
</div>

export default Loading;