import React from 'react';
import Page from '../components/page.component';
import ScrollablePage from '../components/scrollable.page.component';
import Menu from '../components/menu.component';
import MenuItem from '../components/menu.item.component';
import Drawer from '../components/drawer.component';
import Loading from '../components/loading/loading.component';
import API from '../services/api.service';
import AddToCartAnimation from '../animations/addtocart.animation';
import { get, filter, first, last, map, isEmpty, isEqual, find, sumBy, isArray, omit, orderBy, round } from 'lodash';
import moment from 'moment';
import { Promise } from 'bluebird'
import RightDrawer from '../components/right.drawer.component';
import { Item, Button, Modal, Icon, Image, Card, Radio, Form, Checkbox } from 'semantic-ui-react';
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import swal from 'sweetalert';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { OurStory } from './components/OurStory';
import { Cakes } from './components/Cakes';
import { Seasonal } from './components/Seasonal';
import { Faq } from './components/Faq';
import { Footer } from './components/Footer';
import { CartItem } from './components/CartItem';
import { ShoppingCart } from './components/ShoppingCart';
import { RightSideMenu } from './components/RightSideMenu';
import { Constant } from '../common/Constant';
import { PageTopContainer } from './components/PageTopContainer';
import { OrderItem } from './components/OrderItem';

import {ReactComponent as LoadingLogo} from '../components/loading/loading.svg';
const { t, SWAL_TYPES } = Constant;

const MainContent = ({ slides, seasonal, faqs, gotoFaq, showTimer, time, timers, toggleTimer, categories, gotoProduct, gotoCategory, shareProduct, addToShoppingCart }) => 
  <ScrollablePage background={{backgroundImage: 'url(/assets/xlxlxlxl/img/marble_bg.jpg', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'top center'}}>
    <PageTopContainer slides={slides} showTimer={showTimer} time={time} timers={timers} toggleTimer={toggleTimer} gotoProduct={gotoProduct} shareProduct={shareProduct} addToShoppingCart={addToShoppingCart}/>
    <OurStory/>
    <Cakes categories={categories} gotoProduct={gotoProduct} gotoCategory={gotoCategory}/>
    {!isEmpty(first(seasonal)) && <Seasonal product={first(seasonal)} addToShoppingCart={addToShoppingCart}/>}
    <Faq faqs={faqs} gotoFaq={gotoFaq}/>
    <Footer/>
  </ScrollablePage>
export default class XLHomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHealthy: true,
      loading: true,
      open: false,
      shoppingCart: [],
      slides: [],
      categories: [],
      products: [],
      seasonal: [],
      faqs: [],
      timers: [],
      orders: [],
      time: moment(),
      animating: false,
      showTimer: true,
      duration: 800,
      showCart: false,
      toggled: false,
      showModal: false,
      showOrders:  false,
      showDeliveryDetail: false,
      selectedItem: {},
      modalContent: {},
      selectedDate: undefined,
      selectedTime: undefined,
      selectedPlace: undefined,
      selectedPaymentMode: undefined,
      showPaymentDetail: false,
      paymentName: undefined,
      paymentAddr: undefined,
      paymentEmail: undefined,
      paymentConfirmEmail: undefined,
      paymentPhone: undefined,
      showAllOrders: false
    }
    SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);
    this.refOURSTORIES = React.createRef();
  }

  toggleLoading = () => {
    this.setState((prevState)=>({ loading: !prevState.loading }))
  }

  toggleDrawer = () => {
    this.setState((prevState)=>({ open: !prevState.open }))
  }

  toggleCart = () => {
    this.setState((prevState)=>({ showCart: !prevState.showCart, showModal: false }));
    if (this.state.toggled) {
      this.setToggled(false);
    }
  }

  toggleOrders = () => {
    if (this.state.paymentPhone) {
      this.getOrdersByUser(this.state.paymentPhone);
    }
    this.setState((prevState)=>({ showOrders: !prevState.showOrders, showModal: false }));
  }

  setShowOrders = (show) => {
    this.setState({ showOrders: show });
  }

  setShowModal = (show) => {
    this.setState({ showModal: show });
  }

  setShowDeliveryDetail = (show) => {
    this.setState({ selectedDate: undefined, selectedTime: undefined, selectedPlace: undefined, showDeliveryDetail: show });
  }

  setShowPaymentDetail = (show) => {
    this.setState({ showPaymentDetail: show });
  }
  hideCart = () => {
    this.setState({ showCart: false })
  }
  setToggled = (toggle) => {
    this.setState({ toggled: toggle })
  }

  addToShoppingCart = async (item) => {
    await this.syncProducts();
    const newItem = await API.getProductById(item._id);
    if (newItem.stock > 0) {
      newItem.image = item.image;
      this.setState({selectedItem: newItem}, ()=>{
        this.setShowDeliveryDetail(true)
      })
    } else {
      swal(t.cart.fail.title, t.cart.fail.soldout, SWAL_TYPES.ERROR)
    }
  }

  proceedToShoppingCart = async (item) => {
    this.setState({ loading: true });
    if (this.state.shoppingCart.length > 0) {
      this.setState({ loading: false });
      swal(t.payment.fair.title, t.payment.fair.message, SWAL_TYPES.WARNING);
      this.setShowDeliveryDetail(false);
      return;
    }
    const newItem = await API.getProductById(item._id);
    const deliveryDate = this.state.selectedDate;
    const deliveryPlace = this.state.selectedPlace;
    if (!deliveryDate || !deliveryPlace) {
      this.setState({ loading: false });
      swal(t.payment.validate.title, t.payment.validate.message, SWAL_TYPES.WARNING);
      return;
    }
    if (!isEmpty(newItem) && get(newItem, 'stock') > 0 && moment(get(newItem, 'expiryDT')).isSameOrAfter(moment())){
      this.setState((prevState)=>{
        const newShoppingCart = prevState.shoppingCart
        const found = find(newShoppingCart, ({product})=>isEqual(product._id, item._id));
        if (found) {
          found.quantity += 1;
          return { shoppingCart: newShoppingCart, loading: false }
        }
        newShoppingCart.push({deliveryDate, deliveryPlace, product: { ...newItem, image: item.image }, quantity: found ? found.quantity : 1, price: newItem.price * (found ? found.quantity : 1)});
        return { shoppingCart: newShoppingCart, loading: false }}, ()=>this.showAddToCartAnimation())
    }
    this.setShowDeliveryDetail(false);
  }

  alterShoppingCart = (item, mode) => {
    if (mode === 'minus') {
      this.setState(prevState => {
        item.quantity -= 1;
        item.price = get(item.product, 'price') * item.quantity;
        if (item.quantity <= 0) {
          const newCart = filter(prevState.shoppingCart, (cartItem) => cartItem.quantity > 0 );
          return { shoppingCart: newCart }
        } else {
          const newCart = map(prevState.shoppingCart, cartItem => {
            return get(cartItem, 'product._id') === item._id ? item : cartItem;
          });
          return { shoppingCart: newCart }
        }
      })
    } else {
      // swal(t.payment.fair.title, t.payment.fair.message, SWAL_TYPES.WARNING);
      // return;
      this.setState(prevState=>{
        item.quantity += 1;
        if (item.quantity > get(item, 'product.limit')) {
          item.quantity = get(item, 'product.limit')
        }
        if (item.quantity > get(item, 'product.stock')) {
          item.quantity = get(item, 'product.stock')
        }
        item.price = get(item.product, 'price') * item.quantity;
        const newCart = map(prevState.shoppingCart, cartItem => {
          return get(cartItem, 'product._id') === item._id ? item : cartItem;
        });
        return { shoppingCart: newCart }
      })
    }
  }

  showAddToCartAnimation = () => {
    this.setState({ animating: true }, ()=>{
      setTimeout(()=>this.setState({animating: false}), this.state.duration);
    });
  }

  toggleTimer = () => {
    this.setState(prevState => ({ showTimer: !prevState.showTimer }));
  }

  uploadDoc = (id) => {
    document.getElementById(id).click();
  }
  sendWhatsapp = async (order) => {
    const bank = await swal({
      title: `${Constant.t.methods[order.payBy]}付款人姓名（全名）`,
      text: '請閣下輸入銀行卡上的全名，以便我們核對訂單之用。',
      content: 'input',
      button: {
        text: "下一頁",
        closeModal: false,
      },
    }).catch(err => {
      if (err) {
        swal("上傳失敗", "必須填寫付款所用之銀行戶口登記全名以便確認收付，請重新再試或聯繫我們。", SWAL_TYPES.ERROR);
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
    if (!bank) throw null;
    const datetime = await swal({
      title: `${Constant.t.methods[order.payBy]}付款日期及時間`,
      text: '請閣下輸入滙款的日期及時間，以便我們核對訂單之用。',
      content: {
        element: "input",
        attributes: {
          placeholder: "付款日期及時間",
          type: "datetime-local",
        },
      },
      button: {
        text: "確認",
        closeModal: false,
      },
    }).catch(err => {
      if (err) {
        swal("上傳失敗", "必須填寫付款的日期及時間以便確認收付，請重新再試或聯繫我們。", SWAL_TYPES.ERROR);
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
    if (!datetime) throw null;
    const res = order.payBy === Constant.paymentModes.FPS ? await API.postFPSOrder(order._id, { byWhatsapp: true, bank, datetime }) : await API.postBankOrder(order._id, { byWhatsapp: true, bank, datetime });
    if (res) {
      await this.getOrdersByUser(this.state.paymentPhone);
      swal.stopLoading();
      swal.close();
      window.open('https://api.whatsapp.com/send?phone=85263660626&text='+order.pn, '_blank');
    } else {
      throw null;
    }
  }
  handleFileChange = async (ev, order) => {
    console.log(ev.target.files, order);
    if (ev && get(ev, 'target.files') && get(ev, 'target.files.length')) {
      try {
        const bank = await swal({
          title: `${Constant.t.methods[order.payBy]}付款人姓名（全名）`,
          text: '請閣下輸入銀行卡上的全名，以便我們核對訂單之用。',
          content: 'input',
          button: {
            text: "確定",
            closeModal: false,
          },
        }).catch(err => {
          if (err) {
            swal("上傳失敗", "必須填寫付款所用之銀行戶口登記全名以便確認收付，請重新再試或聯繫我們。", SWAL_TYPES.ERROR);
          } else {
            swal.stopLoading();
            swal.close();
          }
        });
        if (!bank) throw null;
        const { _id: image } = await API.postFile(get(ev, 'target.files.0'), order._id);
        if (image && bank) {
          const res = order.payBy === Constant.paymentModes.FPS ? await API.postFPSOrder(order._id, { image, bank }) : await API.postBankOrder(order._id, { image, bank });
          if (res) {
            await this.getOrdersByUser(this.state.paymentPhone);
            swal.stopLoading();
            swal.close();
          } else {
            throw null;
          }
          
        }
      } catch (ex) {
        swal('上傳失敗', '請重新上傳付款憑證。', SWAL_TYPES.ERROR);
      }
      
    } else {
      swal('上傳失敗', '請重新上傳付款憑證。', SWAL_TYPES.ERROR);
    }
  }

  handleDateRadioChange = (e, { value }) => this.setState({ selectedDate: value })
  handleTimeRadioChange = (e, { value }) => this.setState({ selectedTime: value })
  handlePlaceRadioChange = (e, { value }) => {
    if (value === 'Call4Van') {
      swal({
        title: 'Call4Van 需知',
        text: Constant.t.policyCall4Van,
        buttons: {
          cancel: {
            text: "取消",
            value: null,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "確認",
            value: true,
            closeModal: true,
          },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then(confirmed => {
        if (!confirmed) {
          return;
        }
        this.setState({ selectedPlace: value })
      });
    } else {
      this.setState({ selectedPlace: value })
    }
  }
  handlePaymentModeRadioChange = (e, { value }) => this.setState({ selectedPaymentMode: value })
  handlePaymentNameChange = ({ target }) => this.setState({ paymentName: target.value })
  handlePaymentAddrChange = ({ target }) => this.setState({ paymentAddr: target.value })
  handlePaymentPhoneChange = ({ target }) => this.setState({ paymentPhone: target.value })
  handlePaymentEmailChange = ({ target }) => this.setState({ paymentEmail: target.value })
  handlePaymentConfirmEmailChange = ({ target }) => this.setState({ paymentConfirmEmail: target.value })
  handleShowAllOrders = (e, { checked }) => {
    this.setState({ showAllOrders: checked });
  }
  proceedToPayment = async () => {
    await this.syncProducts();
    const res = await Promise.mapSeries(this.state.shoppingCart, async ({product, quantity})=>{
      const newItem = await API.getProductById(product._id);
      if (newItem.stock <= 0 || newItem.stock < quantity || moment().isAfter(newItem.expiryDT)) {
        return false;
      }
      return true;
    })

    if (res.includes(false)) {
      swal(t.payment.fail.title, t.payment.fail.soldout, SWAL_TYPES.ERROR);
      this.setState({ shoppingCart: [] });
      return;
    }
    this.setShowPaymentDetail(true);
  }
  gotoProduct = async (item) => {
    await this.syncProducts();
    await this.addToShoppingCart(item);
  }

  gotoCategory = async(cat) => {
    const category = await API.getCategoryById(cat._id);
    if (category && !isEmpty(category.products)) {
      this.setShowModal(true);
      this.setState({ modalContent: { title: cat.name, content: {...category, image: cat.image} }})
    } else {
      swal('敬請期待', '你所選的蛋糕類別暫時沒有售買，敬請期待。', SWAL_TYPES.INFO)
    }
  }

  gotoFaq = (faq) => {
    this.setShowModal(true);
    this.setState({ modalContent: { title: t.menuItems.FAQ, content: faq }})
  }

  shareProduct = async (item) => {
    const shareData = {
      title: item.name,
      text: item.description,
      url: Constant.domain,
    }

    try {
      await navigator.share(shareData)
    } catch (ex) {
      window.open('mailto:?subject='+shareData.title+'&body='+shareData.text+'\r\n'+shareData.url)
    }
  }

  scrollToSection = (sectionName) => {
    switch (sectionName) {
      case 'OUR STORIES': this.refOURSTORIES.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); break;
      default: break;
    }
  }

  findSeasonal = async (products) => {
    const res = await Promise.mapSeries(filter(products, (product)=>get(product.category, 'isSeasonal')), async (product) => {
      const now = moment();
      const stockDT = moment(product.stockDT);
      const expiryDT = moment(product.expiryDT);
      if (stockDT.isSameOrBefore(now) && expiryDT.isAfter(now)) {
        // const image = await API.getImageObj(first(product.images));
        const image = first(product.images);
        const result = {...product, image };
        return result
      }
    });
    return res
  }

  findTimerProducts = async (products) => {
    const res = await Promise.mapSeries(filter(products, (product)=>product.onTimer && moment(product.stockDT).isSameOrBefore(moment()) && moment(product.expiryDT).isAfter(moment())), async (product) => {
      const now = moment();
      const stockDT = moment(product.stockDT);
      const expiryDT = moment(product.expiryDT);
      if (stockDT.isSameOrBefore(now) && expiryDT.isAfter(now)) {
        // const image = await API.getImageObj(last(product.images))
        const image = last(product.images);
        const result = {...product, image };
        return result
      }
    });
    return res
  }

  digestSlides = async (products) => {
    const res = await Promise.mapSeries(filter(products, (product)=> {
      const now = moment();
      const stockDT = moment(product.stockDT);
      const expiryDT = moment(product.expiryDT);
      return product.onSlide && (stockDT.isSameOrBefore(now) && expiryDT.isAfter(now));
    }), async (product) => {
      // const image = await API.getImageObj(first(product.images))
      const image = first(product.images);
      const result = {...product, image };
      return result
    });
    return res
  }

  getOrdersByUser = async (phone) => {
    try {
      const { _id } = await this.getUser(phone);
      const res = await API.getOrdersByUser(_id);
      this.setState({ orders: res });
    } catch (ex) {
      swal(t.orders.fail.title, t.orders.fail.message, SWAL_TYPES.ERROR)
    }
  }

  showFaqByProduct = async (productId) => {
    try {
      const { guide } = await API.getProductById(productId);
      const faq = await API.getFaqById(guide);
      swal({icon: 'data:'+get(faq, 'image.file.mimetype')+';base64,'+get(faq, 'image.file.data')});
    } catch (ex) {
      swal('', t.guide.fail, SWAL_TYPES.ERROR)
    }
  }

  getUser = async (phone) => API.getUser({ phone: phone, password: phone });

  syncProducts = async () => {
    console.log('sync products')
    const products_results = await API.getProducts();
    const slides_results = await this.digestSlides(products_results);
    const timers_results = await this.findTimerProducts(products_results);
    const seasonal_res = await this.findSeasonal(products_results);
    this.setState((prevState)=>{
      const slides = prevState.slides
      const products = prevState.products
      const seasonal = prevState.seasonal
      const timers = prevState.timers
      slides.splice(0,slides.length)
      products.splice(0,products.length)
      seasonal.splice(0,seasonal.length)
      timers.splice(0,timers.length)
      slides.push(...slides_results)
      products.push(...products_results);
      seasonal.push(...seasonal_res);
      timers.push(...timers_results)
      return { slides: slides, products: products, seasonal: seasonal, timers: timers }}
    )
  }

  doHealthCheck = async () => {
    try {
      const { status } = await API.healthCheck();
      this.setState({ isHealthy: status });
    } catch (err) {
      this.setState({ isHealthy: false });
      console.log(err)
    }
  }

  async componentDidMount() {
    console.log('Call API');
    try {
      await this.doHealthCheck();
      const cats = await API.getCategories();
      const faqs_results = await API.getFaqs();
      this.interval = setInterval(() => this.setState({ time: moment() }), 1000);
      this.setState((prevState)=>{
        const categories = prevState.categories
        const faqs = prevState.faqs
        categories.push(...cats);
        faqs.push(...faqs_results);
        return { categories: categories, faqs: faqs, loading: false }}
      )
      await this.syncProducts();
    } catch (err) {
      console.log(err)
    }
    
  }

  onSuccess = async (payment, purchase) => {
    // 1, 2, and ... Poof! You made it, everything's fine and dandy!
              // console.log("Payment successful!", payment);
              // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data

    await this.donePaypalPayment(payment, purchase);
  }

  onCancel = (data) => {
    // The user pressed "cancel" or closed the PayPal popup
    console.log('Payment cancelled!', data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  }

  onError = (err) => {
    // The main Paypal script could not be loaded or something blocked the script from loading
    console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  }

  calcPaypalCharge = (amount) => {
    const { PAYPAL_CHARGE, PAYPAL_CONSTANT } = Constant;
    return round(amount.toFixed(2) * (1 + PAYPAL_CHARGE) + PAYPAL_CONSTANT, 2)
  }

  donePaypalPayment = async (payment, purchase) => {
    if (purchase && purchase._id) {
      const res = await API.postPaypalOrder(purchase._id, payment);
      if (res) {
        var paragraph = document.createElement("p");
        const text = '*請再次進入我的訂單查閱有關交收資料。';
        paragraph.innerHTML = t.payment.success.message + purchase.pn + '<br>' + t.payment.success.paypal_ref+'<br>' + payment.paymentID + '<br>' + '<span style="color:red">'+text+'</span>'
        swal({
          title: t.payment.success.title,
          content: paragraph,
          icon: SWAL_TYPES.SUCCESS
        });
      } else {
        swal(t.payment.fail.title, t.payment.fail.message(undefined, purchase.payBy, payment.paymentID), SWAL_TYPES.ERROR);
      }
    }
  }

  donePayment = async (payment) => {
    this.setState({ loading: true });
    let userId;
    const { paymentPhone, paymentName, paymentAddr, paymentEmail } = this.state;
    try {
      const user = await this.getUser(paymentPhone);
      userId = user._id;
      console.log(user, userId)
    } catch {
      console.log('New User');
    }
    if (!userId) {
      const user = await API.postUser({ phone: paymentPhone, name: paymentName, password: paymentPhone, address: paymentAddr, email: paymentEmail });
      userId = user._id;
    } else {
      await API.updateUser(userId, { phone: paymentPhone, name: paymentName, password: paymentPhone, address: paymentAddr, email: paymentEmail });
    }
    const items = {
      items: map(this.state.shoppingCart, (item) => {
        return {
          ...item,
          address: this.state.paymentAddr,
          product: omit(item.product, ['image', 'images', 'onSlide', 'stockDT', 'updatedAt', 'deliveryTimeslot', 'deliveryPlace', 'deliveryDate', 'deactivated', 'createdAt', 'category', 'expiryDT'])
        }
      }),
      user: userId,
      status: isEmpty(payment)?0:1,
      payBy: Constant.paymentMap[this.state.selectedPaymentMode],
      amount: this.state.selectedPaymentMode === 'paypal' ? this.calcPaypalCharge(sumBy(this.state.shoppingCart, 'price')) : sumBy(this.state.shoppingCart, 'price').toFixed(2),
      payment
    }
    try {
      const res = await API.postPayment(items);
      if (res && !res.error) {
        this.setState({shoppingCart: [], loading: false})
        this.setShowPaymentDetail(false);
        var paragraph = document.createElement("p");
        const text='* 客人如需查詢訂單資料/是否被確認，可在主頁按「我的訂單」查閱。';
        const info = (res.payBy === Constant.paymentModes.FPS ? t.payment.success.fps_info : (res.payBy === Constant.paymentModes.PAYPAL ? t.payment.success.paypal_info : t.payment.success.bank_info))
        paragraph.innerHTML = t.payment.success.message + res.pn + info + '<br>' + '<span style="color:red">'+text+'</span>'
        swal({
          title:t.payment.success.toPayment,
          content: paragraph,
          icon: SWAL_TYPES.SUCCESS
        });
        this.setState({ paymentAddr: ''})
        this.toggleOrders();
      } else {
        this.setState({ loading: false });
        swal(t.payment.fail.title, t.payment.fail.message(res.message, this.state.selectedPaymentMode, payment?payment.paymentID: undefined), SWAL_TYPES.ERROR);
      }
    } catch (ex) {
      this.setState({ loading: false });
      swal(t.payment.fail.title, t.payment.fail.message(ex), SWAL_TYPES.ERROR);
    }
  }

  validateUserInput = () => {
    const { paymentName, paymentPhone, selectedPaymentMode, paymentAddr, paymentEmail, paymentConfirmEmail, shoppingCart } = this.state;
    const isCall4Van = !!find(shoppingCart, (item) => item.deliveryPlace === 'Call4Van');
    if (paymentPhone && !paymentPhone.match(/[0-9]{8}/g)) {
      return false;
    }
    if (paymentEmail !== paymentConfirmEmail) {
      return false;
    }
    return !!paymentName && !!paymentPhone && selectedPaymentMode && (!isCall4Van || (isCall4Van && paymentAddr)) && paymentEmail && paymentConfirmEmail
  }

  render() {
    let logo = {
      src:"/assets/xlxlxlxl/img/logo.png",
      link:"/"
    }
    let menuItems = [{
      title: t.menuItems.HOME,
      link: '/'
    }, {
      title: t.menuItems.OURSTORY,
      link: '#our_stories'
    }, {
      title: t.menuItems.CAKES,
      link: "#cakes"
    }, {
      title: t.menuItems.SEASONAL,
      link: "#seasonal",
      hidden: isEmpty(first(this.state.seasonal))
    }, {
      title: t.menuItems.FAQ,
      link: "#faq"
    }]
    if (!this.state.isHealthy) {
      return (
        <Page>
          <div className="text-center w-100 p-5">
            <img className="w-100 py-4" style={{maxWidth: 320}} src="/assets/xlxlxlxl/img/logo2.jpeg"></img>
            <p>
              <LoadingLogo style={{width: 32}}></LoadingLogo>網站維護中⋯⋯
            </p>
          </div>
        </Page>
      )
    }
    return (
      <Page>
        <Drawer isOpen={this.state.open} menuItems={menuItems} toggleDrawer={this.toggleDrawer} >
          <Menu toggleDrawer={this.toggleDrawer} sticky={true} logo={logo} className="xl-menu" rightSideMenu={RightSideMenu({ shoppingCart: this.state.shoppingCart, toggleCart: this.toggleCart, orders: filter(orderBy(this.state.orders, (item)=>item.updatedAt, 'desc'), (item) => this.state.showAllOrders || !item.deleted), toggleOrders: this.toggleOrders })} style={{borderBottom: '5px solid var(--color-primary)'}}>
            {menuItems.map(({title, link, hidden, onClick},i)=>hidden ? '' : <MenuItem key={`drawer_item_${i}`} className="px-xxl-5 px-xl-4" title={title} link={link} onClick={onClick}></MenuItem>)}
          </Menu>
          <RightDrawer handleHide={this.hideCart} toggled={this.state.toggled} setToggled={this.setToggled} visible={this.state.showCart} content={ShoppingCart({ shoppingCart: this.state.shoppingCart, alterShoppingCart: this.alterShoppingCart, proceedToPayment: this.proceedToPayment })} children={
            MainContent({slides: this.state.slides, seasonal: this.state.seasonal, faqs: this.state.faqs, time: this.state.time, showTimer: this.state.showTimer, timers: this.state.timers, toggleTimer: this.toggleTimer, categories: this.state.categories, gotoProduct: this.gotoProduct, gotoCategory: this.gotoCategory, shareProduct: this.shareProduct, addToShoppingCart: this.addToShoppingCart, gotoFaq: this.gotoFaq })
          }>
          </RightDrawer>
        </Drawer>
        <Loading show={this.state.loading} text={t.loading}></Loading>
        <AddToCartAnimation show={this.state.animating}></AddToCartAnimation>
        <Modal
          centered={true}
          open={this.state.showDeliveryDetail}
          onClose={()=> this.setShowDeliveryDetail(false)}
          onOpen={()=>this.setShowDeliveryDetail(true)}
        >
          <Modal.Header>{get(this.state.selectedItem, 'name', get(this.state.selectedItem, 'title'))}</Modal.Header>
          <Modal.Content image scrolling>
            {get(this.state.selectedItem, 'image') &&
              <Image size='medium' style={{maxHeight: '40%', overflow: 'hidden'}}  src={API.getResizeImage(get(this.state.selectedItem,'image._id', get(this.state.selectedItem,'image')), 800, 800)} wrapped />
            }
            {<Modal.Description className=" w-100">
                {get(this.state.selectedItem, 'description') && <pre>
                  {get(this.state.selectedItem, 'description')}
                </pre>}
                {get(this.state.selectedItem, 'stock')>0 && <p>限量{get(this.state.selectedItem, 'stock')}份</p>}
                {get(this.state.selectedItem, 'stock')<=0 && <p className="red">已經售完</p>}
                <p></p>
                {get(this.state.selectedItem, 'stock')>0 && <div className="row">
                  <div className="col-6">
                    <Form>
                      <Form.Field>
                        交收日期: <b>{this.state.selectedDate && moment(this.state.selectedDate).format('DD MMM YYYY')}</b>
                      </Form.Field>
                      {map(get(this.state.selectedItem, 'deliveryDate'), ({ date, quota, ordered }) => date && quota && (quota - ordered > 0) ? <Form.Field>
                        <Radio
                          label={`${moment(date).format('DD MMM YYYY')} - 尚餘${quota - ordered}份`}
                          name='dateGrp'
                          value={date}
                          checked={this.state.selectedDate === date}
                          onChange={this.handleDateRadioChange}
                        />
                      </Form.Field> : <Form.Field><p className="red">{`${moment(date).format('DD MMM YYYY')} - 已滿`}</p></Form.Field>)}
                    </Form>
                  </div>
                  
                  <div className="col-6">
                    <Form>
                      <Form.Field>
                        交收地點: <b>{this.state.selectedPlace}</b>
                      </Form.Field>
                      {map(get(this.state.selectedItem, 'deliveryPlace'), ({ place }) => place && <Form.Field>
                        <Radio
                          label={place}
                          name='placeGrp'
                          value={place}
                          checked={this.state.selectedPlace === place}
                          onChange={this.handlePlaceRadioChange}
                        />
                      </Form.Field>)}
                      {!get(this.state.selectedItem, 'hideCall4Van', false) && <Form.Field>
                        <Radio
                          label={'Call4van運費到付(17:00-21:00)'}
                          name='placeGrp'
                          value={'Call4Van'}
                          checked={this.state.selectedPlace === 'Call4Van'}
                          onChange={this.handlePlaceRadioChange}
                        />
                      </Form.Field>}
                    </Form>
                  </div>
                </div>}
                
              </Modal.Description>}
          </Modal.Content>

          <Modal.Actions>
            {get(this.state.selectedItem, 'stock')>0 && <div className="order-btn clickable mb-2" onClick={()=>this.proceedToShoppingCart(this.state.selectedItem)}>加入購物袋</div>}
          </Modal.Actions>
        </Modal>
        <Modal
          centered={true}
          open={this.state.showModal}
          onClose={() => this.setShowModal(false)}
          onOpen={() => this.setShowModal(true)}
        >
          <Modal.Header>{get(this.state.modalContent, 'content.name', get(this.state.modalContent, 'content.title'))}</Modal.Header>
          {!isArray(this.state.modalContent) &&
            <Modal.Content image scrolling>
              {get(this.state.modalContent, 'content.image') && !get(this.state.modalContent, 'content.products') &&
                <Image size='medium' style={{maxHeight: '40%', overflow: 'hidden'}} src={API.getResizeImage(get(this.state.modalContent, 'content.image._id', get(this.state.modalContent, 'content.image')))} wrapped />}

              {get(this.state.modalContent, 'content.description') && <Modal.Description>
                <pre>
                  {get(this.state.modalContent, 'content.description')}
                </pre>

              </Modal.Description>}
              {get(this.state.modalContent, 'content.products') && <Card.Group centered>{map(get(this.state.modalContent, 'content.products'), product=>
                <Card>
                  <Image
                    src={API.getResizeImage(get(product, 'images.0._id', get(product, 'images.0')))}
                  />
                  <Card.Content>
                    <Card.Header>{get(product, 'name')}</Card.Header>
                    <Card.Meta>{'$'+get(product, 'price')}</Card.Meta>
                    <Card.Description>
                      <pre>
                        {get(product, 'description')}
                      </pre>
                    </Card.Description>
                  </Card.Content>
                  <Card.Content extra>
                    {moment(get(product, 'expiryDT')).isSameOrAfter(moment()) && <div className="order-btn clickable mb-2" onClick={()=>this.addToShoppingCart({...product, image: get(product, 'images.0')})}>加入購物袋</div>}
                  </Card.Content>
                </Card>
              )}</Card.Group>}
            </Modal.Content>}
          <Modal.Actions>
            <Button basic onClick={() => this.setShowModal(false)} >
              關閉 <Icon name='close' />
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          centered={true}
          size='fullscreen'
          open={this.state.showPaymentDetail}
          onClose={()=> this.setShowPaymentDetail(false)}
          onOpen={()=>this.setShowPaymentDetail(true)}>
            <Modal.Content scrolling>
              <p>已選購蛋糕：</p>
            <div className="shopping-cart h-auto" style={{marginTop: 0, alignItems: 'flex-start'}}>
              {(this.state.shoppingCart && this.state.shoppingCart.length > 0) ?
                <Item.Group>{map(this.state.shoppingCart, (item, i)=><CartItem key={`cart_${i}`} item={item}></CartItem>)}</Item.Group> :
                <div className="w-100 text-center d-flex py-5" style={{flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                  <img className="mw-100" src="/assets/xlxlxlxl/img/empty-cart.png"></img>
                  <p style={{color: '#888888'}}>你的購物袋裡暫時還沒有蛋糕喔～</p>
                </div>
              }
              {(this.state.shoppingCart && this.state.shoppingCart.length > 0) && 
                <div className="w-100 text-right mb-4" style={{ borderBottom: '1px solid #e6c9cb' }}>合計金額：${this.state.selectedPaymentMode === 'paypal' ? this.calcPaypalCharge(sumBy(this.state.shoppingCart, 'price')) : sumBy(this.state.shoppingCart, 'price').toFixed(2)}</div>}
              <Form>
                <Form.Field>
                  <label>您的姓名：</label>
                  <input placeholder='姓名'
                    maxLength={100}
                    value={this.state.paymentName} 
                    onChange={this.handlePaymentNameChange}/>
                </Form.Field>
                <Form.Field>
                  <label>您的聯絡電話：</label>
                  <input placeholder='聯絡電話' 
                    type='tel'
                    pattern={/[0-9]{8}/g}
                    required
                    maxLength={8}
                    value={this.state.paymentPhone}
                    onChange={this.handlePaymentPhoneChange} />
                  {this.state.paymentPhone && !this.state.paymentPhone.match(/[0-9]{8}/g) && <p className="red">請輸入正確的聯絡電話。</p>}
                </Form.Field>
                <Form.Field>
                  <label>您的電郵地址：</label>
                  <input placeholder='example@email.com'
                    type='email'
                    maxLength={100}
                    value={this.state.paymentEmail} 
                    onChange={this.handlePaymentEmailChange}/>
                </Form.Field>
                <Form.Field>
                  <label>請再次輸入您的電郵地址：</label>
                  <input placeholder='example@email.com'
                    type='email'
                    maxLength={100}
                    value={this.state.paymentConfirmEmail} 
                    onChange={this.handlePaymentConfirmEmailChange}/>
                    {this.state.paymentEmail && this.state.paymentEmail !== this.state.paymentConfirmEmail && <p className="red">請輸入正確的電郵地址。</p>}
                </Form.Field>
                {find(this.state.shoppingCart, (item) => item.deliveryPlace === 'Call4Van') && <Form.Field>
                  <label>您的送貨地址(Call4Van)：</label>
                  <input placeholder='您的送貨地址(Call4Van)'
                    maxLength={200}
                    value={this.state.paymentAddr} 
                    onChange={this.handlePaymentAddrChange}/>
                </Form.Field>}
                {Constant.enablePaypal && <Form.Field>
                  <Radio
                    label={'Paypal (手續費: '+Constant.PAYPAL_CHARGE*100+'% + $'+ Constant.PAYPAL_CONSTANT+')'}
                    name='paymentGrp'
                    value='paypal'
                    checked={this.state.selectedPaymentMode === 'paypal'}
                    onChange={this.handlePaymentModeRadioChange}
                  />
                </Form.Field>}
                <Form.Field>
                  <Radio
                    label='FPS'
                    name='paymentGrp'
                    value='fps'
                    checked={this.state.selectedPaymentMode === 'fps'}
                    onChange={this.handlePaymentModeRadioChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='HSBC'
                    name='paymentGrp'
                    value='bank'
                    checked={this.state.selectedPaymentMode === 'bank'}
                    onChange={this.handlePaymentModeRadioChange}
                  />
                </Form.Field>
              </Form>
              {this.state.selectedPaymentMode === 'fps' && <div className="">
                <p>FPS 付款資訊</p>
                <p>轉數快ID：167296367<br/>
                    商戶名稱：Xlxlxlxl cake shop</p>
                <p className="red">*請於20分鐘內完成付款，並截圖及上傳付款憑證。</p>
                <p className="red">* 如非必要請勿更改訂單，否則每次更改會收取$20行政費用。(交收日期/時間不在更改範圍內）</p>
                <p>如有問題，聯絡客戶服務：<i className="fa fa-whatsapp"></i>63660626</p>
              </div>}
              
              {this.state.selectedPaymentMode === 'bank' && <div className="">
                <p>HSBC銀行轉帳付款資訊</p>
                <p>HSBC：149 606055 838<br/>
                    商戶名稱：Xlxlxlxl cake shop</p>
                <p className="red">*請於20分鐘內完成付款，並截圖及上傳付款憑證。</p>
                <p className="red">* 如非必要請勿更改訂單，否則每次更改會收取$20行政費用。(交收日期/時間不在更改範圍內）</p>
                <p>如有問題，聯絡客戶服務：<i className="fa fa-whatsapp"></i>63660626</p>
              </div>}
            </div>
            </Modal.Content>
            
            <Modal.Actions>
              {this.validateUserInput() && 
                <div className="order-btn clickable" onClick={()=>this.donePayment()}>確認</div>}
              {!this.validateUserInput() && 
                <p className="red">請告訴我們您的姓名、聯絡電話、電郵地址及付款方式。</p>}
            </Modal.Actions>
        </Modal>

        <Modal
          centered={true}
          size='fullscreen'
          open={this.state.showOrders}
          onClose={()=> this.setShowOrders(false)}
          onOpen={()=>this.setShowOrders(true)}>
            <Modal.Content scrolling>
              
              <div className="shopping-cart h-auto" style={{marginTop: 0, alignItems: 'flex-start'}}>
                <Form>
                  <Form.Field>
                    <label>以聯絡電話查找訂單：</label>
                    <div className="d-flex" style={{alignItems: 'center'}}>
                      <input placeholder='聯絡電話' 
                        type='tel'
                        required
                        maxLength={8}
                        value={this.state.paymentPhone}
                        onChange={this.handlePaymentPhoneChange} />
                        <Button color="pink" icon="search" shape="circle" size="small" onClick={() => {
                          this.getOrdersByUser(this.state.paymentPhone)
                        }}></Button>
                    </div>
                    
                    {this.state.paymentPhone && !this.state.paymentPhone.match(/[0-9]{8}/g) && <p className="red">請輸入正確的聯絡電話。</p>}

                    <div className="d-flex" style={{alignItems: 'center'}}>
                        <p style={{lineHeight: '33px', marginBottom: 0, marginRight: 8}}>顯示歷史訂單</p>
                        <Checkbox toggle onChange={this.handleShowAllOrders} color='pink'></Checkbox>
                      </div>
                  </Form.Field>
                </Form>
                {(this.state.orders && this.state.orders.length > 0) ?
                  <Item.Group  className="w-100">{map(filter(orderBy(this.state.orders, (item)=>item.updatedAt, 'desc'), (item) => this.state.showAllOrders || !item.deleted), (item, i)=><OrderItem key={`cart_${i}`} item={item} uploadDoc={this.uploadDoc} handleFileChange={this.handleFileChange} showFaqByProduct={this.showFaqByProduct} sendWhatsapp={this.sendWhatsapp} paypalButton={
                      <PaypalExpressBtn env={Constant.env} client={Constant.client} currency={'HKD'} total={item.amount} 
                        onSuccess={payment => this.onSuccess(payment, item)}
                        onCancel={this.onCancel}
                        onError={this.onError}
                      />}></OrderItem>)}</Item.Group> :
                  (
                    <div className="w-100 text-center d-flex py-5" style={{flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                      <img className="mw-100" src="/assets/xlxlxlxl/img/empty-cart.png"></img>
                      <p style={{color: '#888888'}}>找不到你的訂單～</p> 
                    </div>
                  )
                  
                }
                <div className="my-4">
                  <p>FPS 付款資訊</p>
                  <p>轉數快ID：167296367<br/>
                      商戶名稱：Xlxlxlxl cake shop</p>
                  <p>HSBC銀行轉帳付款資訊</p>
                  <p>HSBC：149 606055 838<br/>
                      商戶名稱：Xlxlxlxl cake shop</p>
                  <p className="red">*請於20分鐘內完成付款，並截圖及上傳付款憑證。</p>
                  <p className="red">* 如非必要請勿更改訂單，否則每次更改會收取$20行政費用。(交收日期/時間不在更改範圍內）</p>
                  <p>如有問題，聯絡客戶服務：<i className="fa fa-whatsapp"></i>63660626</p>
                </div>
                
              </div>
            </Modal.Content>
        </Modal>
      </Page>
    );
  }
}