import { PageSection } from './PageSection';
export const OurStory = () => <PageSection id="our_stories">
  <div className="col-6 justify-content-center text-center py-5">
    <h4 className="section-title">OUR STORIES</h4>
    <img className="mw-100 py-4" src="/assets/xlxlxlxl/img/logo2.jpeg"></img>
    <p>日本北海道牛乳千層蛋糕 SINCE 2013<br/>兩個食法 creamy/ice cream<br/>特濃牛乳cream夠滑夠濃厚<br/>曾有蘋果日報.飲食男女和新假期報道<br/>及新城電台訪問</p>
    <div className="slide-btns justify-content-center">
      <div className="ig-btn clickable mr-0" onClick={()=>window.open('https://www.instagram.com/xlxlxlxl/?hl=zh-hk', '_blank')} ><i className="fa fa-instagram" aria-hidden="true"></i></div>
    </div>
  </div>
</PageSection>