import {
  Grid,
  Segment,
  Sidebar,
} from 'semantic-ui-react'

const RightDrawer = ({ visible, children, content, handleHide, toggled, setToggled }) => {

  return (
    <Grid columns={1}>
      <Grid.Column>
        <Sidebar.Pushable>
          <Sidebar
            as={Segment}
            animation='push'
            direction='right'
            vertical
            onHide={() => {
              if (toggled) {
                handleHide();
                setToggled(false);
              } else {
                setToggled(true);
              }
            }}
            visible={visible}
            width='wide'
          >
            {content}
          </Sidebar>

          <Sidebar.Pusher dimmed={visible}>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Grid.Column>
    </Grid>
  )
}

export default RightDrawer