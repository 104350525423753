export const Constant = {
  domain: 'https://xlxlxlxl.com.hk',
  PAYPAL_CHARGE: 0.042,
  PAYPAL_FIXCHARGE: 0.039,
  PAYPAL_CONSTANT: 2.35,
  enablePaypal: false,
// env: 'sandbox',
  env: 'production',
  client: {
    sandbox:    'AYeKuBAm2uZZXEomSDlDr5rk_gIugy47co48SMz4YLPMd3GHW5Z0fcvL9PRKIKkb1EFfBMj1MXor49im',
    production: 'Ady62AkJ84Hpc9KzA4TtllL8Zaqkbu8mW7_67NyrM4XARK3sImERDebis99uXMhaJfSEbFkoRjsjBQR9',
  },
  paymentModes: {
    PAYPAL: 'PAYPAL',
    FPS: 'FPS',
    BANK: 'BANK'
  },
  paymentMap: {
    paypal: 'PAYPAL',
    fps: 'FPS',
    bank: 'BANK'
  },
  SWAL_TYPES: {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info'
  },
  t: {
    loading: 'Baking',
    methods: {
      PAYPAL: 'PayPal',
      FPS: 'FPS',
      BANK: '轉帳'
    },
    menuItems: {
      HOME: 'HOME',
      OURSTORY: 'OUR STORIES',
      CAKES: 'CAKES',
      SEASONAL: 'SEASONAL',
      FAQ: 'FAQ',
      CONTACT: 'CONTACT'
    },
    orders: {
      fail: {
        title: '找不到訂單',
        message: '請輸入正確的電話號碼。'
      },
    },
    payment: {
      success: {
        title: "交易成功",
        toPayment: "請前往付款",
        message: "我們已確認上述的訂購，單號： ",
        fps_info: '<br>FPS 付款資訊<br>轉數快ID：167296367<br>商戶名稱：Xlxlxlxl cake shop<br>此訂單將於20分鐘後失效，請於訂單失效前以FPS付款並截圖及以聯絡號碼查找訂單上傳付款憑證以保留訂單。<br>如有問題，請聯絡客戶服務(Whatsapp)：63660626',
        fps_message: (pn) => "我們已確認上述的訂購，單號： "+pn+('\r\nFPS 付款資訊\r\n轉數快ID：167296367\r\n商戶名稱：Xlxlxlxl cake shop\r\n此訂單將於20分鐘後失效，請於訂單失效前以FPS付款並截圖及以聯絡號碼查找訂單上傳付款憑證以保留訂單。\r\n如有問題，請聯絡客戶服務(Whatsapp)：63660626'),
        paypal_ref: "參考編號：",
        paypal_info: '<br>請於20分鐘內完成PayPal付款程序，以免訂單失效。',
        bank_info: '<br>HSBC銀行轉帳付款資訊<br>HSBC：149 606055 838<br>商戶名稱：Xlxlxlxl cake shop<br>此訂單將於20分鐘後失效，請於訂單失效前完成付款並截圖及以聯絡號碼查找訂單上傳付款憑證以保留訂單。<br>如有問題，請聯絡客戶服務(Whatsapp)：63660626',
      },
      fail: {
        title: "交易失敗",
        message: (ex, selectedPaymentMode, paymentID) => "我們未能為您確認上述的訂購，原因：" + (ex ? Constant.t.cart.fail.soldout : "\r\n網絡異常，請稍後再試。\r\n" +(selectedPaymentMode !== 'paypal'? '': '參考編號：\r\n'+paymentID)),
        soldout: "所選的蛋糕已經售完，請期待下次發售。"
      },
      fair: {
        title: "請分開下單",
        message: "網站暫不能一併處理\n購買不同項目產品\n請分開下單 感謝🤍"
      },
      validate: {
        title: "我們還需要多一些資訊",
        message: "請選擇交收日期及地點。",
      }
    },
    cart: {
      fail: {
        title: '添加失敗',
        soldout: '蛋糕已經全部售完',
      }
    },
    policyCall4Van: "運費請自行到Call4van app 查詢運費，出發點在牛頭角(運費約$90-200不等)，另有隧道附加費。",
    infoSelf: "訂單已完成✅\r\n\r\n1️⃣1️⃣工作室地址 : \r\n牛頭角偉業街99號連順工業大廈3樓23室\r\n（電梯到3字樓後，按23#接通工作室開門)\r\\r\n2️⃣工作室取貨時，必須提供訂單編號及下單電話號碼\r\n\r\n❗️❗️若客人不在下單時所選擇的指定日子及時間出現，訂單將視為棄單，不設退款❗️❗️",
    infoCall4Van: "🍰訂單已完成❤️\r\n注意事項‼️\r\nCALL4VAN:1700-2100(不設指定收貨時間)\r\n‼️運費到付（只限地面交收)，並確定該地址於17:00-21:00有人收貨。‼️\r\n‼️請留意交收日期‼️\r\n‼️請於以上時間留意接聽電話‼️\r\n如無人收貨/長時間無人接聽司機收貨電話，會以取消訂單處理，所有繳付金額亦不設退還。\r\n‼️出發點在牛頭角 運費請自行到Call4van app 查詢 另外所顯示價錢需額外付加隧道費 實際運費以Call4van app為準，本店不獲追究。‼️",
    guide: {
      fail: '找不到食用方法。'
    },
    PRODUCT_OUT_OF_STOCK: {

    }
  }
}