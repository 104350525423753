import React from 'react';

import {
  BrowserRouter as Router,
  Routes as Routers,
  Route
} from "react-router-dom";

import XLHomePage from '../xlxlxlxl/pages/home.page';

const xlxlxlxl_preview = () => (
  <div className="w-100">
    <div className="row no-gutters">
      <div className="col-12">
        <div className="top-bar" style={{ verticalAlign: 'middle'}}>
          <div className="text-center text-white">
            <h6 className="mt-1 px-4">Xlxlxlxl Cake Shop Design Preview</h6>
          </div>
        </div>
      </div>
    </div>
    <div className="container my-5 mx-auto">
      <div className="row justify-content-center">
        <div className="col-6 text-center hover-box p-4" >
          <a href="xlxlxlxl/index" style={{color: '#e6c9cb'}}>
            <div className="w-100 p-absolute" style={{overflow: 'hidden', maxHeight:'480px', border: '1px solid #e6c9cb'}}>
              <img className="w-100" style={{top: 0, left: 0, right: 0}} src="/assets/xlxlxlxl/index_preview.jpg"></img>
            </div>
            <div className="w-100 text-center my-3">
              <p>Xlxlxlxl Cake Shop Design 01<br/>Index page</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
);
const xlxlxlxl_preview_index_page = () => (
  <div className="vw-100" style={{overflowX: 'hidden'}}>
    <div className="row no-gutters">
      <div className="col-12">
        <div className="top-bar" style={{ verticalAlign: 'middle'}}>
          <div className="text-white px-4" style={{fontSize: '18px'}}><a className="text-white" href="../xlxlxlxl"><i className="fa fa-chevron-left" aria-hidden="true"></i></a></div>
          <div className="text-center text-white">
            <h6 className="mt-1">Xlxlxlxl Cake Shop Design 01</h6>
          </div>
        </div>
      </div>
    </div>
    <div className="row no-gutters" style={{marginTop:'48px'}}>
      <div className="col-12">
        <img className="w-100" src="/assets/xlxlxlxl/index_preview.jpg"/>
      </div>
    </div>
  </div>);


export default class Routes extends React.Component {
  render() {
    return (
      <Router>
        <Routers>
          <Route exact path="/" element={<XLHomePage/>} />
        </Routers>
      </Router>
    );
  }
}
