import { Item, Button, Icon } from 'semantic-ui-react';
import { get } from 'lodash';
import moment from 'moment';
import swal from 'sweetalert';
import { Constant } from '../../common/Constant';

const { t, SWAL_TYPES } = Constant;
const STATUS = ["未付", "訂單已確認", "等待確認"];
const STATUS_COLOR = ["red", "green", "blue"];
export const OrderItem = ({item, uploadDoc, handleFileChange, showFaqByProduct, paypalButton, sendWhatsapp}) => <Item>
<Item.Content verticalAlign='middle' style={{ border: '1px solid #6e6e6e', borderRadius: 8, padding: 16}}>
  <Item.Header>{get(item, 'items.0.product.name')} (單號：{get(item, 'pn')})</Item.Header>
  <Item.Description>
    <div className="row">
      <div className="col-6">
        單價：${get(item, 'items.0.price')} (支付方式：{get(item, 'payBy')})<br/>
        數量：{get(item, 'items.0.quantity')}<br/>
        交收：{moment(get(item, 'items.0.deliveryDate')).format('DD MMM YYYY')} @ {get(item, 'items.0.deliveryPlace')}<br/>
        
      </div>
      <div className="col d-block text-left text-md-right">
        <div style={{marginBottom: '4px'}}>
          {get(item, 'status') === 0 && !get(item, 'deleted', false) ? 
          <div>
            {item.payBy !== Constant.paymentModes.PAYPAL ? <Button size='small' color='pink' style={{color:'#171717', marginBottom: 8}} onClick={() => uploadDoc('file_'+get(item, 'pn'))}>
              <Icon name="upload"></Icon>按此上傳付款憑證 ({(Math.floor(+moment.duration(moment(item.expiryDT).diff(moment(), false)).asMinutes() % 60))}:{(Math.floor(+moment.duration(moment(item.expiryDT).diff(moment(), false)).asSeconds() % 60))})
            </Button> : paypalButton}
            <input hidden type="file" id={'file_'+get(item, 'pn')}  accept="image/*" onChange={(ev)=>handleFileChange(ev, item)}></input>
            {item.payBy !== Constant.paymentModes.PAYPAL && <Button basic size='small' color='green' onClick={() => sendWhatsapp(item)}>
            <i className="fa fa-whatsapp"></i>&nbsp;({(Math.floor(+moment.duration(moment(item.expiryDT).diff(moment(), false)).asMinutes() % 60))}:{(Math.floor(+moment.duration(moment(item.expiryDT).diff(moment(), false)).asSeconds() % 60))})
            </Button>}
            {get(item, 'status') === 0 && !get(item, 'deleted', false) && <p style={{color:'#189144'}}>如付款後不能於網頁上傳付款憑證，請按「WhatsApp 」按鍵以提交付款憑證。</p>}
          </div> : 
          <Button basic size='small' color={!get(item, 'deleted', false) ? STATUS_COLOR[get(item, 'status')] : 'grey'} disabled={true} >{!get(item, 'deleted', false) ? STATUS[get(item, 'status')] : "已取消"}</Button>}
        </div>
        {item.payBy !== Constant.paymentModes.PAYPAL && get(item, 'status') === 2 && !get(item, 'deleted', false) && get(item, 'payment.byWhatsapp', false) && <button className="ui green small button">
          <a href={"https://api.whatsapp.com/send?phone=85263660626&text="+get(item, 'pn')} target="_blank">
            <i className="fa fa-whatsapp text-white"></i>
          </a>
        </button>}
        {get(item, 'status') === 2 && !get(item, 'deleted', false) && <p>我們會在兩個工作天內確認訂單</p>}
        {!get(item, 'deleted', false) && get(item, 'status') === 1 && <div><Button basic size='small' color='grey' icon='info' onClick={()=>{
          swal('', get(item, 'items.0.deliveryPlace') === 'Call4Van' ? t.infoCall4Van :  t.infoSelf, SWAL_TYPES.INFO, { button: '食用方法' }).then((value) => showFaqByProduct(get(item, 'items.0.product._id')))
        }}>交收資料</Button></div>}
        
      </div>
    </div>
    
  </Item.Description>
  <Item.Extra>
    {get(item, 'status') === 0 && !get(item, 'deleted', false) && <p>訂單確認後，若不是本公司問題，款項一概不會退回。</p>}
    {[Constant.paymentModes.FPS, Constant.paymentModes.BANK].includes(item.payBy) && get(item, 'status') === 2 && !get(item, 'deleted', false) && <p style={{color:'red'}}>* 客人如需查詢訂單資料/是否被確認，可在主頁按「我的訂單」查閱。</p> }
  </Item.Extra>
</Item.Content>
</Item>