import { first } from 'lodash';
import { Swiper } from 'swiper/react';
import { SocialBar } from './SocialBar';
import { ProductSlide } from './ProductSlide';
import { TimerContainer } from './TimerContainer';
import {ReactComponent as LoadingLogo} from '../../components/loading/loading.svg';
import moment from 'moment';

export const PageTopContainer = ({slides, showTimer, timers, time, toggleTimer, gotoProduct, shareProduct, addToShoppingCart}) => {
  const pad = (num, size) => {
    let tmp = num + '';
    while (tmp.length < size) tmp = '0' + tmp;
    return tmp;
  }

  return (
    <div className="page-top-container">
      <SocialBar items={[{type:'facebook', link:'https://www.facebook.com/Xlxlxlxlcakeshop/'}, {type: 'instagram', link: 'https://www.instagram.com/xlxlxlxl/?hl=zh-hk'}]}></SocialBar>
      <Swiper className="w-100 h-100" slidesPerView={1} autoplay={true}>
        {slides && slides.length && slides.map((product, i)=> ProductSlide({ item: product, gotoProduct, shareProduct, addToShoppingCart }, i))}
      </Swiper>
      {timers.length>0 && first(timers) && (showTimer ? <TimerContainer product={first(timers) || {}} addToShoppingCart={addToShoppingCart} toggleTimer={toggleTimer} time={time}/>:
        <div className="show-btn" onClick={()=>toggleTimer()}>
          <LoadingLogo style={{width: 48}}/>
          <p className="" style={{ fontSize: 12 }}>限時搶購{(first(timers) || {}).name}<br/>尚餘：
            {pad(Math.floor(+moment.duration(moment((first(timers) || {}).expiryDT).diff(time, false)).asHours()), 2)}小時
            {pad(Math.floor(+moment.duration(moment((first(timers) || {}).expiryDT).diff(time, false)).asMinutes() % 60), 2)}分
            {pad(Math.floor(+moment.duration(moment((first(timers) || {}).expiryDT).diff(time, false)).asSeconds() % 60), 2)}秒
          </p>
        </div>)}
      <div className="float-btn py-3">
        <a href="https://api.whatsapp.com/send?phone=85263660626" target="_blank">
          <i className="fa fa-whatsapp my-float"></i>
        </a>
      </div>
    </div>
  )
}