import { Item, Input, Button } from 'semantic-ui-react';
import { get } from 'lodash';
import moment from 'moment';
import API from '../../services/api.service';

export const CartItem = ({item, alterShoppingCart}) => <Item>
<Item.Image size='tiny' src={API.getResizeImage(get(item.product, 'image._id', get(item.product, 'image')))} />
<Item.Content verticalAlign='middle'>
  <Item.Header>{get(item.product, 'name')}</Item.Header>
  <Item.Description>單價：${get(item.product, 'price')}{ !alterShoppingCart && <span> x {item.quantity}件</span>}<br/><br/>交收：{moment(item.deliveryDate).format('DD MMM YYYY')} @ {item.deliveryPlace}</Item.Description>
  <Item.Extra>
      <Input
        value={item.quantity} size='mini' actionPosition='left' labelPosition='right' type='text' disabled placeholder='數量'>
        {alterShoppingCart && <Button basic size='mini' icon="minus" color='grey' onClick={() => alterShoppingCart(item, 'minus')}>
        </Button>}
        {alterShoppingCart && <input />}
        {alterShoppingCart && <Button basic size='mini' icon="plus" color='pink' onClick={()=> alterShoppingCart(item, 'plus')}>
        </Button>}
      </Input>
    </Item.Extra>
</Item.Content>
</Item>