const BASE_URL = 'https://api.sarielapps.com/xlxlxlxl/api/'

const get = async (endpoint) => (await fetch(`${BASE_URL}${endpoint}`)).json();

const post = async (endpoint, data) => (await fetch(`${BASE_URL}${endpoint}`, {
  method:'POST',
  body: JSON.stringify(data),
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})).json();

const put = async (endpoint, data) => (await fetch(`${BASE_URL}${endpoint}`, {
  method:'PUT',
  body: JSON.stringify(data),
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})).json();

const uploadfile = async (endpoint, data, ref = undefined) => {
  const formData  = new FormData();
  formData.append('file', data);
  if (ref) {
    formData.append('ref', ref);
  }
  const response = await fetch(`${BASE_URL}${endpoint}`, {
    method: 'POST',
    body: formData
  });
  return response.json();
}

const getSlides = async () => await get('slides');
const getProducts = async () => await get('product');
const getProductById = async (_id) => await get(`product/${_id}`);
const getCategories = async () => await get('category');
const getCategoryById = async (_id) => await get(`category/${_id}`);
const getFaqs = async () => await get('faq');
const getFaqById = async (_id) => await get(`faq/${_id}`);

const getOrdersByUser = async (userId) => await get(`user/${userId}/orders/`);

const getImage = async (id) => await get('file/base64/'+id);
const getImageObj = async (id) => await get('file/'+id);

const postPayment = async (items) => await post('purchase', items);
const getUser = async (phone) => await post('login', phone);
const postUser = async (user) => await post('users', user);
const updateUser = async (_id, user) => await put(`users/${_id}`, user);

const postFile = async (file, ref) => await uploadfile('file', file, ref);

const postFPSOrder = async (id, payment) => await post(`purchase/fps/${id}`, payment);
const postBankOrder = async (id, payment) => await post(`purchase/bank/${id}`, payment);
const postPaypalOrder = async (id, payment) => await post(`purchase/paypal/${id}`, { payment });

const healthCheck = async () => await get('health');

const getResizeImage = (id, width = 800, height = 800) => `${BASE_URL}file/resize/${id}/${width}/${height}`;

const API = {
  BASE_URL,
  getSlides,
  getProducts,
  getProductById,
  getCategories,
  getCategoryById,
  getFaqs,
  getImage,
  getImageObj,
  getUser,
  getOrdersByUser,
  getFaqById,
  postUser,
  postPayment,
  postFile,
  postFPSOrder,
  postBankOrder,
  postPaypalOrder,
  healthCheck,
  getResizeImage,
  updateUser,
}

export default API;