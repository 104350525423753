import MenuItem from "./menu.item.component"

const Drawer = ({children, isOpen, menuItems, toggleDrawer}) => (<div>
  <div className={"xl-menu-nav " + (isOpen ? "open-menu":"close-menu")}>
    <div className="d-flex w-100 h-100">
      <div className="inner-menu">
        {menuItems.map(({title, link, hidden}, i)=> hidden ? '' : <MenuItem key={`menu_${i}`} className="menu-item" title={title} link={link}></MenuItem>)}
      </div>
      <div className="backdrop" onClick={isOpen ? toggleDrawer : undefined}></div> 
    </div>
  </div>
  <div className="inner-page">
    {children}
  </div>
</div>)

export default Drawer