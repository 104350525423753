import { get } from 'lodash';
import moment from 'moment';
import API from '../../services/api.service';

export const TimerContainer = ({ product = {}, addToShoppingCart, toggleTimer, time}) => {
  const pad = (num, size) => {
    let tmp = num + '';
    while (tmp.length < size) tmp = '0' + tmp;
    return tmp;
  }

  const { _id, image, name, description, category, price, ...others } = product

  return (<div className="timer-container" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: 'url(/assets/xlxlxlxl/img/marble_bg_b.jpg)'}}>
    <div className="row no-gutters w-100">
      <div className="col-4" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover', backgroundImage: 'url('+API.getResizeImage(get(image, '_id', image))+')'}}></div>
      <div className="col-8 p-3 p-lg-5">
        <h2>限時搶購</h2>
        <h2>{name}</h2>
        <p className="mt-4">{(category || {}).name}</p>
        <div className="d-flex justify-content-between">
          <h6><span>${price}</span>&nbsp;起</h6>
          <div className="order-btn clickable" onClick={()=>addToShoppingCart({_id, image, name, description, category, price, ...others})}>現在訂購</div>
        </div>
        <div className="timer d-flex justify-content-between mt-4">
          <div className="time-box p-3">
            <h4>{pad(Math.floor(+moment.duration(moment(others.expiryDT).diff(time, false)).asHours()), 2)}</h4>
            <p>....</p>
            <b>小時</b>
          </div>
          <div className="time-box p-3">
            <h4>{pad(Math.floor(+moment.duration(moment(others.expiryDT).diff(time, false)).asMinutes() % 60), 2)}</h4>
            <p>....</p>
            <b>分</b>
          </div>
          <div className="time-box p-3">
            <h4>{pad(Math.floor(+moment.duration(moment(others.expiryDT).diff(time, false)).asSeconds() % 60), 2)}</h4>
            <p>....</p>
            <b>秒</b>
          </div>
        </div>
      </div>
      
    </div>
    <div className="hide-btn" onClick={()=>toggleTimer()}>
      <p className="text-white" style={{ fontSize: 12 }}>隱藏</p>
    </div>
  </div>)
}