import { SwiperSlide } from 'swiper/react';
import { get } from 'lodash';
import API from '../../services/api.service';

export const CategorySlide = ({item, gotoProduct, gotoCategory}, i) =>{
  const {_id, name, image} = item
  return (<SwiperSlide key={`cat_slide_${i}`}>
    <div className="w-100 h-100 row no-gutters">
      <div className="col-12">
        <div className="w-100 h-100 text-center clickable" onClick={()=>gotoCategory(item)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', backgroundImage: 'url('+API.getResizeImage(get(image,'_id', image),800,800)+')', backgroundSize: 'cover', backgroundPosition:'center center', minHeight:480}}>
          <div className="category-btn mx-auto">
            {name.slice(0, 5)}<br/>{name.slice(5)}
          </div>
        </div>
      </div>
    </div>
  </SwiperSlide>)
}