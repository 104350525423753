import { CartItem } from './CartItem';
import { Item } from 'semantic-ui-react';
import { map, sumBy } from 'lodash';

export const ShoppingCart = ({shoppingCart, alterShoppingCart, proceedToPayment}) => <div>
<div className="shopping-cart">
  {(shoppingCart && shoppingCart.length > 0) ?
    <Item.Group>{map(shoppingCart, (item, i)=><CartItem key={`cart_${i}`} item={item} alterShoppingCart={alterShoppingCart}></CartItem>)}</Item.Group> :
    <div className="w-100 text-center d-flex py-5" style={{flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
      <img className="mw-100" src="/assets/xlxlxlxl/img/empty-cart.png"></img>
      <p style={{color: '#888888'}}>你的購物袋裡暫時還沒有蛋糕喔～</p>
    </div>
  }
  
</div>
{(shoppingCart && shoppingCart.length > 0) && 
  <div className="footer-btns">
    <div className="w-100 text-right" style={{ borderBottom: '1px solid #e6c9cb' }}>合計金額：${(sumBy(shoppingCart, 'price').toFixed(2))}</div>
    <div className="order-btn clickable mt-2" onClick={()=> proceedToPayment()}>前往付款</div>
  </div>}
</div>