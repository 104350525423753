import { PageSection } from './PageSection';
export const Footer = () => <PageSection>
<div className="col-12 justify-content-center text-center pt-5">
  <div className="footer">
    <img style={{width: 180, marginTop: -50}} src="/assets/xlxlxlxl/img/footer_logo.png"></img>
    <p className="pb-3"><i className="fa fa-copyright"></i> Copyright 2021 Xlxlxlxl Cake Shop. All rights reserved.<br/>Licensed: 2951806850</p>
  </div>
  <div className="slide-btns large justify-content-center">
    <div className="ig-btn clickable" onClick={()=>window.open('https://www.instagram.com/xlxlxlxl/?hl=zh-hk', '_blank')} ><i className="fa fa-instagram" aria-hidden="true"></i></div>
    <div className="fb-btn clickable" onClick={()=>window.open('https://www.facebook.com/Xlxlxlxlcakeshop/', '_blank')} ><i className="fa fa-facebook" aria-hidden="true"></i></div>
  </div>
</div>
</PageSection>