import { PageSection } from './PageSection';
import { get } from 'lodash';
import API from '../../services/api.service';

export const Seasonal = ({product = {}, addToShoppingCart}) => {
  const { _id, image, name, description, category, price, ...others } = product;
  return (<PageSection id="seasonal">
    <div className="col-10 col-lg-8 justify-content-center text-center py-5 mt-5">
      <h4 className="section-title">SEASONAL</h4>
      <h6 className="section-subtitle">Celebrates with your loved ones</h6>
      <div className="seasonal-banner row no-gutters my-5" style={{backgroundImage: 'url('+API.getResizeImage(get(image, '_id', image))+'")', backgroundRepeat: 'no-repeat', backgroundSize: (window.innerWidth < 768 ? '100%':'50%'), backgroundPosition: 'right top'}}>
        <div className="col-12 content row no-gutters">
          <div className="col-12 col-lg-8">
            <h6 className="title py-2">{name}</h6>
            <p className="desc py-2">{description}</p>
            <div className="buy-btn" onClick={()=>addToShoppingCart({_id, image, name, description, category, price, ...others})}>立即訂購</div>
          </div>
        </div>
      </div>
    </div>
  </PageSection>)}