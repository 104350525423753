import { SwiperSlide } from 'swiper/react';
import { get } from 'lodash';
import API from '../../services/api.service';

export const ProductSlide = ({item, gotoProduct, shareProduct, addToShoppingCart}, i) => {
  const {_id, name, description, category, image, price, stock, ...others} = item || {}
  return item && (<SwiperSlide key={`slide_${i}`}>
    <div className="w-100 h-100 row no-gutters">
      <div className="col-12 col-lg-5"><div className="w-100 h-100" style={{ backgroundImage: 'url('+ API.getResizeImage(get(image,'_id', image),800,800)+')', backgroundSize: 'cover', backgroundPosition:'center center', minHeight:240}}></div></div>
      <div className="col-12 col-lg-7">
        <div className="w-100 h-100 px-5 d-flex" style={{ backgroundColor: '#b4b7ba', flexDirection: 'column', justifyContent:'center', overflow: 'hidden'}}>
          <div className="slide-title">{name.slice(0, 5)}<br/>{name.slice(5)}</div>
          <div className="slide-cat py-2 py-md-5"><span>{i+1}</span> {category.name}</div>
          <div className="slide-btns">
            <div className="details-btn clickable" onClick={()=>gotoProduct(item)}><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
            <div className="share-btn clickable" onClick={()=>shareProduct(item)}><i className="fa fa-share-alt" aria-hidden="true"></i></div>
          </div>
          <div className="slide-desc py-2 py-md-5">
            <pre>{description}</pre>
            {stock>0 && <p>限量{stock}份</p>}
            {stock<=0 && <p className="red">已經售完</p>}
          </div>
          {stock>0 && <div className="order-btn clickable mb-2" onClick={()=>addToShoppingCart({_id, name, description, category, image, price, ...others})}>立即訂購</div>}
        </div>
      </div>
    </div>
  </SwiperSlide>)
}