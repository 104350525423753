import { SwiperSlide } from 'swiper/react';
import { get } from 'lodash';

export const FaqSlide = ({item, gotoFaq}, i) => {
  const {_id, title, image} = item;
  return(<SwiperSlide key={`faq_slide_${i}`}>
  <div className="w-100 h-100 row no-gutters">
    <div className="col-12">
      <div className="w-100 text-center clickable" onClick={()=>gotoFaq(item)} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
        <div style={{backgroundImage: 'url(data:'+get(image, 'file.mimetype')+';base64,'+get(image, 'file.data')+')', backgroundSize:'cover', backgroundRepeat: 'no-repeat', backgroundPosition:'center bottom', minHeight: 360}} />
        <div className="faq-btn mx-auto">
          {title}
        </div>
      </div>
    </div>
  </div>
</SwiperSlide>)
}
