import { PageSection } from './PageSection';
import { FaqSlide } from './FaqSlide';
import { filter } from 'lodash';
import { Swiper } from 'swiper/react';

export const Faq = ({faqs, gotoFaq}) => <PageSection id="faq">
<div className="col-10 col-lg-8 justify-content-center text-center py-5 mb-5">
  <h4 className="section-title">FAQ</h4>
  <Swiper className="w-100 h-100 my-5" slidesPerView={1} autoplay={true} breakpoints={{'1200': { slidesPerView:1, spaceBetween:36 }, '768': { slidesPerView: 1, spaceBetween:24 },  '360': { slidesPerView: 1, spaceBetween: 0 }}} spaceBetween={36} loop={false}>
    {filter(faqs, ({ display }) => !!display).map((faq, i)=> FaqSlide({item: faq, gotoFaq}, i))}
  </Swiper>
</div>
</PageSection>