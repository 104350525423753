import { PageSection } from './PageSection';
import { CategorySlide } from './CategorySlide';
import { Swiper } from 'swiper/react';
import { filter } from 'lodash';

export const Cakes = ({categories, gotoProduct, gotoCategory}) => <PageSection id="cakes">
  <div className="col-10 col-lg-8 justify-content-center text-center py-5 mb-5">
    <h4 className="section-title">CAKES</h4>
    <h6 className="section-subtitle">|| Millie Crepe cake you will get addicted to ||</h6>
    <Swiper className="w-100 h-100 my-5" slidesPerView={1} autoplay={true} breakpoints={{'1200': { slidesPerView:3, spaceBetween:36 }, '768': { slidesPerView: 2, spaceBetween:24 },  '360': { slidesPerView: 1, spaceBetween: 0 }}} spaceBetween={36} loop={true}>
      {filter(categories, ({ display }) => !!display).map((cat, i)=> CategorySlide({item:cat, gotoProduct, gotoCategory}, i))}
    </Swiper>
  </div>
</PageSection>