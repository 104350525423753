import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './routes/Routes';
import * as serviceWorker from './serviceWorker';
import SwiperCore, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'semantic-ui-css/semantic.min.css'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './overrides.css';
SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);
ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
